import store from "@/store/index";

// let dominio_gestao = store.getters.layoutConfig("distribuidor.dominio_gestao");
// let tipo_conexao = store.getters.layoutConfig("distribuidor.tipo_conexao");
// let distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");
// let   "../v2";

// if (dominio_gestao == "localhost:8080") {
//   link_v2 = tipo_conexao + "://localhost/acampa-gestao-pra/v2";
// } else {
//   link_v2 = "../v2";
// }

// let link_v3 = "../v3";
// let menuCalculadora = {};

// if (
//   distribuidor == "atria" ||
//   distribuidor == "homologacao" ||
//   distribuidor == "localhost"
// ) {
//   menuCalculadora = {
//     heading: "Calculadora de Preços",
//     route: "calculadora-precos",
//     nivelCargo: [-1],
//     // fontIcon: "fa-th-large",
//     svgIcon: "./media/icons/duotune/shopping/calculator.svg",
//   };
// }
//comentado temporariamente
const DocMenuConfig = [
  {
    route: "/",
    pages: 
    [
      {
        sectionTitle: "Lista de Tickets",
        nivelCargo: [100,200],
        svgIcon: "./media/icons/duotune/general/clipboard-check-duotone.svg",
        sub: [
          {
            heading: "Suporte e chat",
            route: "/listaTickets",
            nivelCargo: [100, 200],
          },
        ],
      },
      {
        sectionTitle: "Config",
        nivelCargo: [100, 200],

        svgIcon: "./media/icons/duotune/general/cogs.svg",
        sub: [
          {
            heading: "Formulários",
            route: "/createFormTickets",
            nivelCargo: [100, 200],
          },
          {
            heading: "Usuários",
            route: "/users",
            nivelCargo: [100, 200],
          },
          {
            heading: "Projetos",
            route: "/projectsList",
            nivelCargo: [100],
          },
          {
            heading: "Alerta sonoro",
            route: "/alertSound",
            nivelCargo: [100, 200],
          },
        ],
      },
    ],
  },
];

export default DocMenuConfig;
